import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { D } from '@dayetopia/library';
import Footer from '../components/Footer';

export default function Home() {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (typeof document !== `undefined`) {
      const body = document.body
      if (body.classList.contains("no-js-loaded")) {
        setLoaded(false)
      } else {
        setLoaded(true)
      }
    }
  }, [])

  return (
    <>
      <PageContainer loaded={loaded}>
        {loaded && (
          <div>
            <Content>
            <article id="terms">
                <h2>Terms &amp; Conditions</h2>
                <section id="terms-and-conditions">
                  <p>
                    <strong>WEBSITE TERMS OF USE</strong>
                  </p>
                  <p>
                    <strong>What do these Terms cover?</strong>
                  </p>
                  <p>
                    The terms and conditions shown on this page, together with the
                    documents and other webpages referred to on it (collectively,
                    the “Terms”) govern:
                  </p>
                  <ol>
                    <li>
                      your purchase and our supply of any of the goods
                      (“Products”), including by way of a subscription, listed on
                      our website www.yourdaye.com (“our Site”) from time to time;
                      and
                    </li>
                    <li>
                      your access and use of our Site, (collectively, the
                      “Services”)
                    </li>
                  </ol>
                  <p>
                    <strong>Why should you read these Terms?</strong>
                  </p>
                  <p>
                    The Terms create a legally binding agreement between you and
                    us that governs your purchase of any Products from us via the
                    Site (your “Order”).
                  </p>
                  <p>
                    <strong>
                      You have to confirm that you can enter the agreement
                      outlined in these Terms.
                    </strong>
                  </p>
                  <p>
                    By clicking to confirm during the checkout process, you agree
                    that you accept these Terms and promise to us that you have
                    the right, authority, and capacity to enter into these Terms.
                    If you use the Site, but don’t make an Order, you accept these
                    Terms (as they relate to your use of the Site) by accessing or
                    using the Site.
                  </p>
                  <p>
                    <strong>Our Services are for consumers only.</strong>
                  </p>
                  <p>
                    We only supply our Services for non-commercial, non-business,
                    private use by Consumers. By accepting these Terms you promise
                    to us that you are a consumer and not acting in the course of
                    a business.
                  </p>
                  <p></p>
                  <p>
                    <strong>
                      Information about Daye and our contact details
                    </strong>
                  </p>
                  <p>
                    <strong>Who we are</strong>&#8203;.&nbsp;&#8203;“Daye” is the
                    trading name of Anne’s Day Limited, a company registered in
                    England under company number 11044785.
                  </p>
                  <p>
                    <strong>Where we are based</strong>&#8203;.&nbsp;&#8203;Our
                    registered office is Studio Cc301, The Biscuit Factory,
                    Clements Road, London, England, SE16 4DG
                  </p>
                  <p>
                    How to contact us.&nbsp;&#8203;You can contact us by writing
                    to us at&nbsp;&#8203;hello@yourdaye.com
                  </p>
                  <p>
                    <strong>How we may contact you</strong>&#8203;.&nbsp;&#8203;If
                    we have to contact you we may do so by email, post or
                    telephone to the
                  </p>
                  <p>
                    address/phone number you provided when you went through the
                    checkout process on our Site or that is registered on your
                    Account.
                  </p>
                  <p></p>
                  <p>
                    <strong>Our contract</strong>
                  </p>
                  <p>
                    <strong>How our contract is formed.&nbsp;</strong>&#8203;Our
                    acceptance of an Order occurs when we send you a confirmation
                    email and give you an order number. It is at this point that a
                    contract between you and us will come into existence – as
                    noted above, this contract is governed and conditioned by
                    these Terms.
                  </p>
                  <p></p>
                  <p>
                    <strong>Accounts</strong>
                  </p>
                  <p>
                    <strong>Account Creation.&nbsp;</strong>&#8203;Although you
                    can go through the order process as a guest, you may choose to
                    register for an account on our Site (“Account”). Registering
                    for an account will allow you to benefit from increased
                    functionality and features. If you choose to register you will
                    have to provide certain information about yourself as prompted
                    by the account registration form.
                  </p>
                  <p>
                    <strong>Accurate and up-to-date Information.&nbsp;</strong>
                    &#8203;If you do create an Account, all the registration
                    information you submit should be truthful and accurate. If for
                    any reason any information you submit becomes untruthful,
                    inaccurate and/or incomplete, you should update that
                    information to maintain its accuracy.
                  </p>
                  <p>
                    <strong>
                      What to do if you want to delete your Account.&nbsp;
                    </strong>
                    &#8203;You can delete your Account at any time, for any
                    reason, by following the instructions on the Site.
                  </p>
                  <p>
                    <strong>You are responsible for your Account.&nbsp;</strong>
                    &#8203;You are responsible for maintaining the confidentiality
                    of your Account log-in information (including, for example,
                    your password). Accordingly, you are responsible for all
                    activities that occur under your Account.
                  </p>
                  <p>
                    <strong>
                      What to do if you become aware of, or suspect, unauthorised
                      use of your Account.&nbsp;
                    </strong>
                    &#8203;You should notify us immediately if you suspect or
                    become aware of any unauthorised use of your Account or any
                    other breach of its security.
                  </p>
                  <p></p>
                  <p>
                    <strong>Delivery</strong>
                  </p>
                  <p>
                    <strong>Delivery costs</strong>&#8203;.&nbsp;&#8203;Standard
                    UK delivery using the Royal Mail of all Daye Products is
                    included in the prices you see on the Site – there are no
                    hidden costs added at checkout.
                  </p>
                  <p>
                    <strong>Where does delivery take place?&nbsp;</strong>
                    &#8203;Delivery of Products takes place at the front door of
                    the property at the address you specified for delivery. This
                    means that if the Products are being delivered to a flat or
                  </p>
                  <p>
                    property with a reception/concierge, delivery shall take place
                    at the front door of this property and not at the door of the
                    specific property within an apartment block. Provided that we
                    deliver the Products to the address you have specified in the
                    order (or any other address agreed by us in writing) then the
                    Products will be deemed to have been delivered and we will not
                    be liable to you for non delivery of the Products, to the
                    fullest extent permitted by law. We do not have to satisfy
                    ourselves that the person who accepts delivery at the address
                    you have provided is you or is a person authorised by you to
                    accept delivery of the Products.
                  </p>
                  <p>
                    <strong>When you become responsible for the Products.</strong>
                    &#8203;&nbsp;&#8203;Any Products in your Order will be your
                    responsibility from the time we deliver them to you in
                    accordance with this section and the Terms generally.
                  </p>
                  <p>
                    <strong>Split shipments.&nbsp;</strong>&#8203;For logistical
                    reasons, Daye reserves the right to split shipments. This
                    means that if you order multiple Products in one Order, the
                    individual Products may be sent and arrive separately and
                    potentially by different carriers.
                  </p>
                  <p>
                    <strong>When we will provide the Products.&nbsp;</strong>
                    &#8203;During the order process we will give you an estimated
                    date of delivery for the Products - this will depend on the
                    delivery option selected. This date is an estimate only, but
                    we will try our best to make sure we meet it. That said, if
                    our supply of the Products is delayed, we will contact you as
                    soon as possible to let you know and we will take steps to
                    minimise the effect of the delay.
                  </p>
                  <p>
                    <strong>Products being returned.&nbsp;</strong>&#8203;With
                    regards to tampons, due to the intimate nature of the products
                    Daye cannot accept any returns of tampons that are not in
                    their complete and original packaging. If you are unhappy with
                    the product for whatever reason, please e-mail us
                    on&nbsp;&#8203;hello@yourdaye.com&nbsp;within 28 days of
                    delivery and you will be reimbursed. If you return three boxes
                    for a refund, you will be banned from purchasing through our
                    Site.
                  </p>
                  <p></p>
                  <p>
                    <strong>Price</strong>
                  </p>
                  <p>
                    Our prices on our UK website are in GBP and include all taxes
                    (for example, our prices include VAT at the rate that is in
                    force on the date you make a payment). The price that you will
                    actually pay in respect of any Order will be the amount shown
                    to you at the checkout page.
                  </p>
                  <p>
                    Daye reserves the right to change the prices shown for the
                    Products at any time.
                  </p>
                  <p></p>
                  <p>
                    <strong>Payment</strong>
                  </p>
                  <p>Payment can occur by listed means only.</p>
                  <p>
                    You can pay for your purchase by all means listed from time to
                    time as available on the Site. The account associated with
                    your payment method will be charged at the time the Order is
                    accepted. We are not responsible to you for any losses that
                    you might suffer as a result of trying to pay for an Order by
                    any other means than those offered on the Site.
                  </p>
                  <p>You authorise us to charge your payment card account.</p>
                  <p>
                    By submitting an Order and providing us your payment card
                    details at checkout, you fully authorise Daye to use those
                    payment card details to pay for your Order and that such
                    payment means legally giving access to sufficient funds to
                    cover all costs of the Order. Daye cannot be held liable for
                    any fraudulent use of the payment method used.
                  </p>
                  <p></p>
                  <p>
                    <strong>Late payments.</strong>
                  </p>
                  <p>
                    Daye reserves the right to suspend or cancel any Order and /
                    or delivery if you do not make the necessary payment to us
                    when it is due – for example, the payment card details you
                    entered at checkout are incorrect or the payment card account
                    they relate to does not have sufficient funds to cover your
                    Order. The delivery of any new Order may be suspended in case
                    of late payment of a previous Order, notwithstanding the
                    provisions hereof.
                  </p>
                  <p>
                    <strong>Store your own records.</strong>
                  </p>
                  <p>
                    It is up to you to save and print the certificate of payment
                    if you want to keep the banking details of your Order – we
                    won’t store these for you.
                  </p>
                  <p></p>
                  <p>
                    <strong>Product Availability</strong>
                  </p>
                  <p>
                    <strong>
                      What happens if a Product in your Order is
                      unavailable?&nbsp;
                    </strong>
                    &#8203;In case of unavailability of one or more Products after
                    placing the Order, we will notify you by email or telephone.
                    The amount of the Order will be recalculated and you will be
                    charged the new amount, which will be: your original Order
                    value, less the value of the missing Products. If the Order is
                    entirely unavailable, we will notify you by email or telephone
                    and your payment card account will not be charged for that
                    Order (or it will be refunded if it has already been charged).
                  </p>
                  <p>
                    <strong>
                      We are not liable for Product unavailability.&nbsp;
                    </strong>
                    &#8203;In case of unavailability of Products before or after
                    Order, (subject to the “Our liability” section of these Terms)
                    Daye will not be liable for any loss you suffer nor liable to
                    you for any damages as a result of the unavailability of such
                    Product(s), to the fullest extent permitted by law.
                  </p>
                  <p></p>
                  <p>
                    <strong>How long does my subscription last.&nbsp;</strong>
                    &#8203;If you sign up to a subscription service for our
                    Products the subscription shall continue until it is cancelled
                    by us or by you.
                  </p>
                  <p>
                    <strong>How do I cancel my subscription.&nbsp;</strong>
                    &#8203;You can cancel your subscription at any time by
                    contacting us at hello@yourdaye.com or by following the
                    instructions on the Site. Please note that if you cancel your
                    subscription within 5 days of your next subscription payment
                    date, you shall be charged that subscription amount and the
                    contract shall cancel thereafter.
                  </p>
                  <p>
                    <strong>Change of Address.&nbsp;</strong>&#8203;It is your
                    responsibility to ensure that the address we have for you is
                    the correct one. Should your address change during a
                    subscription you must notify us of such change, if the change
                    is notified to us within 5 days of a subscription payment
                    whilst we will do our best to process the change we cannot
                    guarantee that it will be processed in time for the next
                    immediate shipment.
                  </p>
                  <p></p>
                  <p>
                    <strong>Product Warranty</strong>
                  </p>
                  <p>
                    If you are not entirely happy with your Daye purchase you
                    have&nbsp;&#8203;28 days&#8203;&nbsp;to let us know by
                    e-mailing hello@yourdaye.com Please note that it may take up
                    to 7 days for your bank to credit your account. We are not
                    liable, and cannot take responsibility for, any bank charges
                    that you may incur during the refund process.
                  </p>
                  <p>
                    Any refund you are eligible for will be paid when the
                    collection has been made and our quality control teams have
                    assessed the Products for any damage and confirmed they are
                    unused and unopened.
                  </p>
                  <p></p>
                  <p>
                    <strong>Intellectual Property</strong>
                  </p>
                  <p>
                    <strong>Licence.&nbsp;</strong>&#8203;Subject to these Terms
                    (in particular, the restrictions outlined in the following
                    section), Daye grants you a licence to use and access the Site
                    on the basis that this licence is:
                  </p>
                  <ol>
                    <li>
                      not a commercial licence – you can only access and use the
                      Site for your own personal, non-commercial uses (i.e., not
                      for your business);
                    </li>
                    <li>
                      non-transferable – you can’t pass this right to someone
                      else;
                    </li>
                    <li>
                      non-exclusive – other people can access and use the Site;
                    </li>
                    <li>
                      revocable – we have the right to remove your ability to use
                      the Site in accordance with these Terms; and
                    </li>
                    <li>
                      limited – the licence does not extend beyond what has just
                      been described above.
                    </li>
                  </ol>
                  <p>
                    <strong>Restrictions.&nbsp;</strong>&#8203;The rights granted
                    to you in these Terms are subject to a number of important
                    restrictions, particularly relating to how you access and use
                    the Site and Daye’s property that is comprised in the Site. In
                    particular, you agree that your use of the Site is subject to
                    the following restrictions:
                  </p>
                  <ol>
                    <li>
                      you shall not license, sell, rent, lease, transfer, assign,
                      distribute, host, or otherwise commercially exploit the
                      Site, whether in whole or in part, or any content displayed
                      on the Site;
                    </li>
                    <li>
                      you shall not modify, make derivative works of, disassemble,
                      reverse compile or reverse engineer any part of the Site,
                      otherwise that as permitted by applicable law for
                      interoperability purposes;
                    </li>
                    <li>
                      you shall not access the Site in order to build a similar or
                      competitive website, product, or service; and
                    </li>
                    <li>
                      except as expressly stated in these Terms, you agree that no
                      part of the Site may be copied, reproduced, distributed,
                      republished, downloaded, displayed, posted or transmitted in
                      any form or by any means.
                    </li>
                  </ol>
                  <p>
                    Unless otherwise indicated, any future release, update, or
                    other addition to functionality or content of the Site shall
                    be subject to these Terms. All copyright and other proprietary
                    notices on the Site (or on any content displayed on the Site)
                    must be retained on all copies thereof.
                  </p>
                  <p>
                    <strong>Ownership.&nbsp;</strong>&#8203;You acknowledge and
                    agree that all the intellectual property rights, including
                    copyrights, patents, trade marks, and trade secrets, in the
                    Site, any content shown on the Site, and on the Products
                    themselves, are owned by Daye or Daye’s suppliers. Neither
                    these Terms (nor your access to the Site) transfers to you or
                    any third party any rights, ownership or similar interests in
                    or to such intellectual property rights in the Services,
                    except for the limited access rights expressly set forth in
                    this section (Intellectual Property). Daye and its suppliers
                    reserve all rights not granted in these Terms. There are no
                    implied licences granted under these Terms.
                  </p>
                  <p>
                    <strong>Copyright/Trademark Information.&nbsp;</strong>
                    &#8203;Copyright © 2019 Anne’s Day Limited. All rights
                    reserved. All trademarks, logos and service marks (“Marks”)
                    displayed on the Site are our property or the property of
                    other third parties. You are not permitted to use these Marks
                    without our prior written consent or the consent of such third
                    party which may own the Marks.
                  </p>
                  <p></p>
                  <p>
                    <strong>Written communications</strong>
                  </p>
                  <p>Email counts as “in writing”.</p>
                  <p>
                    When we use the words “writing” or “written” in these Terms,
                    this includes emails. For contractual purposes, you: (a)
                    consent to receiving communications from Daye by email; and
                    (b) agree that all communications that we provide to you by
                    email satisfy any legal requirement that such communications
                    would satisfy if it were be in a hardcopy writing.
                  </p>
                  <p></p>
                  <p>
                    <strong>Our liability</strong>
                  </p>
                  <p>
                    <strong>
                      We are responsible to you only for foreseeable loss and
                      damage caused by us.&nbsp;
                    </strong>
                    &#8203;If we fail to comply with these Terms, we are
                    responsible for loss or damage you suffer that is a
                    foreseeable result of our breaking these Terms or our failing
                    to use reasonable care and skill, but we are not responsible
                    for any loss or damage that is not foreseeable. Loss or damage
                    is foreseeable if either it is obvious that it will happen or
                    if, at the time these Terms are entered into and both we and
                    you knew it might happen during the Services.
                  </p>
                  <p>
                    <strong>What we do not exclude.&nbsp;</strong>&#8203;Nothing
                    in these Terms shall limit or exclude our liability for:
                  </p>
                  <p>
                    1.&nbsp;death or personal injury resulting from our
                    negligence; 2.&nbsp;fraud or fraudulent misrepresentation;
                    3.&nbsp;your key legal rights described below;
                    4.&nbsp;defective products under the Consumer Protection Act
                    1987; or 5.&nbsp;any other liability that cannot be excluded
                    or limited by English law.
                  </p>
                  <p>
                    <strong>We are not liable for business losses.&nbsp;</strong>
                    &#8203;We only supply the Products and the Services for
                    domestic and private use. If you use the Products for any
                    commercial, business or re-sale purpose we will have no
                    liability to you for any loss of profit, loss of business,
                    business interruption, or loss of business opportunity.
                  </p>
                  <p>
                    <strong>
                      We are not liable for events outside our control.&nbsp;
                    </strong>
                    &#8203;We will not be liable or responsible for any failure to
                    perform, or delay in performance of, any of our obligations
                    that is caused by events outside our reasonable control
                    (“Force Majeure Event”). A Force Majeure Event includes any
                    act, event, non-happening, omission accident or Act of God
                    beyond our reasonable control. Our performance of the Services
                    is deemed to be suspended for the period that the Force
                    Majeure Event continues, and we will have an extension of time
                    for performance for the duration of that period. We will use
                    our reasonable endeavours to bring the Force Majeure Event to
                    a close or to find a solution by which our obligations under
                    the Terms may be performed despite the Force Majeure Event.
                  </p>
                  <p>
                    <strong>
                      We are not liable where you use the Products outside their
                      intended use.&nbsp;
                    </strong>
                    &#8203;Should you use the Products in a manner which is not
                    their intended use or, or what would be regarded as their
                    intended use, or in accordance with recommendations then we
                    shall not be liable for any loss suffered.
                  </p>
                  <p>
                    <strong>
                      We are not responsible for any indirect or consequential
                      loss.
                    </strong>
                    &#8203;&nbsp;Notwithstanding the foregoing, except as
                    precluded by law, we will not be liable to you for any
                    indirect or consequential loss, damage or expenses (including
                    loss of profits, business or goodwill) howsoever arising out
                    of any problem you notify
                  </p>
                  <p>
                    us of under this condition, and we will have no liability to
                    pay any money to you by way of compensation other than to
                    refund to you the amount paid by you for the Products.
                  </p>
                  <p>
                    <strong>No limitation of statutory rights.</strong>
                    &#8203;&nbsp;Notwithstanding the foregoing, nothing in these
                    terms and conditions is intended to limit any rights you might
                    have as a consumer under applicable United Kingdom law or
                    other statutory rights that may not be excluded nor in any way
                    to exclude or limit our liability to you for any death or
                    personal injury resulting from our negligence.
                  </p>
                  <p>
                    <strong>Responsibility for suitability and CBD.</strong>
                    &#8203;&nbsp;You are responsible for ensuring that all items
                    sold to you are appropriate for your use and do not contain
                    any substances that may cause you harm (i.e. allergies/
                    negative reactions). Some of our Products contain CBD oil and
                    it is your responsibility to ensure the appropriateness of
                    using such product for you, if you are unsure you should
                    consult your physician.
                  </p>
                  <p></p>
                  <p>
                    <strong>Our right to vary these terms and conditions</strong>
                  </p>
                  <p>
                    <strong>Small changes.&nbsp;</strong>&#8203;From to time to
                    time, we may make changes to the Services or these Terms:
                  </p>
                  <p>
                    to improve or enhance any feature(s) of the Services; to
                    ensure the ongoing viability of the Services; to reflect
                    changes in relevant laws and regulatory requirements; and to
                    implement minor technical adjustments and improvements, for
                    example to address a security threat.
                  </p>
                  <p>
                    <strong>More significant changes.&nbsp;</strong>&#8203;In
                    addition, we may make certain more significant changes to the
                    Services or these Terms, but if we do so and we consider these
                    changes will materially or adversely affect you or your
                    rights, we will notify you of such changes.
                  </p>
                  <p>
                    <strong>The timing of any significant changes.&nbsp;</strong>
                    &#8203;The Terms that will apply to your purchase of any
                    Products will be those that are in force at the time you place
                    your Order, unless:
                  </p>
                  <ol>
                    <li>
                      any changes we subsequently make to these Terms or the
                      Services have been required by law or governmental authority
                      (in which case it will apply to orders previously placed by
                      you); or
                    </li>
                    <li>
                      if we have notified you of a change to the Terms that apply
                      and will materially affect your Order before we send you the
                      delivery confirmation and you have confirmed that you wish
                      to proceed with your Order.
                    </li>
                  </ol>
                  <p>
                    <strong>Privacy and security policy.&nbsp;</strong>&#8203;You
                    will be required to provide certain personal information to us
                    as a necessary prerequisite of being able to place an Order.
                    Our policies and procedures relating to your information are
                    outlined in our&nbsp;
                    <a
                      target="_blank"
                      rel="noopener"
                      href="https://yourdaye.com/privacy-policy/"
                    >
                      &#8203;https://yourdaye.com/privacy-policy/
                    </a>
                  </p>
                  <p>
                    <strong>
                      We will only use your data outlined in our privacy
                      policy.&nbsp;
                    </strong>
                    &#8203;We shall not be liable where we are unable to perform
                    or delayed in performing our obligations where such inability
                    or delay is due to you not providing personal information
                    required to perform such obligations.
                  </p>
                  <p></p>
                  <p>
                    <strong>Other Terms &amp; Conditions</strong>
                  </p>
                  <p>
                    <strong>Products may vary from their pictures.&nbsp;</strong>
                    &#8203;Daye aims to give you the information you need to know
                    the essential characteristics of the Products. Daye agrees
                    that the photographs of the Products on the Site should be as
                    faithful as is possible to the actual Products themselves.
                    However, given the digital mode of presentation, it is
                    possible that your perception may not exactly match the
                    relevant Product itself.
                  </p>
                  <p>
                    <strong>Compliance with UK Laws.&nbsp;</strong>&#8203;All the
                    Products are made to comply with applicable UK laws and
                    regulations.
                  </p>
                  <p>
                    <strong>Headings and Interpretation.&nbsp;</strong>&#8203;The
                    section titles in these Terms are for convenience only and
                    have no legal or contractual effect. The word “including”
                    means “including without limitation”.
                  </p>
                  <p>
                    <strong>
                      We may transfer our rights under these Terms to someone
                      else.&nbsp;
                    </strong>
                    &#8203;We may transfer our rights and obligations under these
                    Terms to another organisation – for example, this could
                    include another member of our group of companies or someone
                    who buys our business. We will always tell you in writing if
                    this happens and we will ensure that the transfer will not
                    affect your rights under these Terms.
                  </p>
                  <p>
                    <strong>
                      Nobody else has any rights under these Terms.&nbsp;
                    </strong>
                    &#8203;These Terms are between you and us. No other person
                    shall have any rights to enforce any of its terms.
                  </p>
                  <p>
                    <strong>
                      If a court finds part of these Terms illegal, the rest will
                      continue in force.&nbsp;
                    </strong>
                    &#8203;Each of the sections of these Terms operates
                    separately. If any court or relevant authority decides that
                    any of them are unlawful, the remaining sections will remain
                    in full force and effect.
                  </p>
                  <p>
                    <strong>
                      Even if we delay enforcing our rights under these Terms, we
                      can still enforce them later.&nbsp;
                    </strong>
                    &#8203;If we do not insist immediately that you do anything
                    you are required to do under these Terms, or if we delay in
                  </p>
                  <p>
                    taking steps against you in respect of your breaking these
                    Terms, that will not mean that you do not have to do those
                    things and it will not prevent us taking steps against you at
                    a later date.
                  </p>
                  <p>
                    <strong>
                      Which laws apply to these Terms and where you may bring
                      legal proceedings?&nbsp;
                    </strong>
                    &#8203;These Terms are governed by English law and we both
                    agree to submit to the non exclusive jurisdiction of the
                    English courts. This means that, if you are a citizen of a
                    country in the EU, you may bring a claim to enforce your
                    consumer protection rights in connection with these Terms in
                    England or in the EU country in which you live.
                  </p>
                  <p>
                    <strong>Dispute resolution.&nbsp;</strong>&#8203;If you have a
                    dispute with us relating to our products or service offered,
                    in the first instance please contact us
                    at&nbsp;&#8203;hello@yourdaye.com&#8203;&nbsp;and attempt to
                    resolve the dispute with us informally. You can also write to
                    us at: Daye, Studio Cc301, The Biscuit Factory, Clements Road,
                    London, England, SE16 4DG. In the unlikely event that we are
                    not able to resolve the dispute informally, you can refer to
                    The European Online Dispute Resolution, which can be accessed
                    at&nbsp;&#8203;http://ec.europa.eu/odr
                  </p>
                  <p>
                    <strong>Discount code</strong>&#8203;. Any prices and offers
                    are correct at the time of the discount codes being published
                    by Daye through Daye communications. Daye cannot take
                    responsibility for discount codes published without permission
                    on third-party websites. Discount codes may be subject to
                    additional terms and conditions (including an expiry
                    date/time) which will be shared at the point of publication
                    and are subject to availability. Codes cannot be used in
                    conjunction with other offers and only one code can be used
                    per order.
                  </p>
                  <p>
                    Invariably on rare occasions our discount codes calculate
                    incorrectly and apply incorrectly to your basket at checkout.
                    Should this happen we shall notify you as soon as we become
                    aware, but in any event prior to your goods being dispatched,
                    and reserve the right to cancel the order. Should this occur
                    you shall be given the option to cancel or amend your order in
                    line with how the discount code should have been correctly
                    applied.
                  </p>
                  <p>
                    <strong>Terms last updated </strong>12 August 2019{" "}
                  </p>
                  <p></p>{" "}
                </section>
                <section id="pharmacy">
                  <p>
                    <strong>PHARMACY TERMS OF USE</strong>
                  </p>
                  <p>THESE TERMS AND CONDITIONS (“TERMS”) APPLY TO ALL TRANSACTIONS, INVOLVING THE SALE AND DELIVERY OF MEDICAL CONSULTATIONS AND PRESCRIPTION MEDICATIONS THROUGH THE WEBSITE DAYE (THE “WEBSITE”). BY CLICKING THE CONFIRM ORDER BUTTON, YOU ARE AGREEING TO THESE TERMS. THESE TERMS CREATE A CONTRACT BETWEEN YOU AND HEALTH COUNTER LIMITED, COMPANY NUMBER 06646711 AND REGISTERED OFFICE AT 5 PETERWOOD PARK, PETERWOOD WAY, CROYDON, CR04UQ (“PHARMACY”). MHRA registration (medicine-seller-register.mhra.gov.uk/search-registry/149).</p>
                  <p>Pharmacy sells medical consultations and certain prescription medications through the Website. The Website is owned and operated by Daye, company number 11044785 and with registered address at Studio Cc301, The Biscuit Factory, Clements Road, London, England, SE16 4DG. Pharmacy fills and dispenses medications through Cedarwood pharmacy registered with the General Pharmaceutical Council with number 1121830 (https://www.pharmacyregulation.org/registers/pharmacy/registrationnumber/1121830), with Superintendent Pharmacist: Yuk Shan Wong, GPhC registration number 2071680 (pharmacyregulation.org/registers/pharmacist/registrationnumber/2071680). The pharmacy provides medical consultations through Stadn Ltd, with registered address at 52 High Street, Pinner, Middlesex, HA5 5PW. Please follow the link to check on the prescriber’s registration status https://www.cqc.org.uk/location/1-6322360454.</p>
                  <p><strong>Knowledge and Consent.</strong> By clicking “Confirm Order”, you acknowledge that you are of the age of majority, of sound mind, and consent to these Terms with full knowledge and without undue influence or duress and that you have every opportunity to obtain independent legal advice with respect to these Terms.</p>
                  <p><strong>Power of Attorney.</strong> You authorize and appoint Daye as your attorney and agent, to take all steps, sign all documents, and act on your behalf as if you were personally present and acting for yourself for the limited purposes of facilitating the purchase of medical consultations and pharmaceutical products through Pharmacy. This authorization shall include but not be limited to, the collection of your personal and personal health information and the disclosure of such information to Pharmacy and any other health professionals being retained on your behalf, as required. You may revoke this authorization at any time by notifying Pharmacy in writing, but if you revoke this authorization you will not be able to purchase medical consultations or pharmaceutical products through the Website. This authorization shall continue until you revoke it.</p>
                  <p><strong>Prescription.</strong> You acknowledge that the Pharmacy will not accept any order unless it receives a valid prescription issued by a licensed physician based upon a personal consultation and examination. The Pharmacy shall present a completed questionnaire and medication request to a GMC licensed physician for the issuance of a prescription.</p>
                  <p><strong>Personal Examination.</strong> You acknowledge that you are of the age of 18+, of sound mind, and consent to these Terms with full knowledge and without undue influence or duress. You confirm that all information provided by you in the medical consultation questionnaire or elsewhere is correct and true, to the best of your knowledge. If you have trouble understanding the questionnaire, please get in contact with us. Do not answer the questionnaire until you are clear on the meaning of each question.</p>
                  <p><strong>Delivery.</strong> Delivery of the pharmaceuticals you order from the Pharmacy takes place when the pharmaceuticals leave the Pharmacy. After delivery, the Pharmacy will, as attorney and agent for you, arrange for the shipping of the product to your home address. Normally, shipments take an average of 48 hours to arrive. In the event that your shipment is delayed beyond 72 hours, and upon notice from you, the Pharmacy will arrange for a replacement order to be sent to you at no additional cost. Title to the medications that you purchase passes to you when the medications leave the Pharmacy. The Pharmacy is not liable for any damages suffered due to delay in shipment or products damaged in transit.</p>
                  <p><strong>Warranties.</strong> THE MANUFACTURER OF EACH PRODUCT IS SOLELY RESPONSIBLE FOR ANY WARRANTY ASSOCIATED WITH A PRODUCT. TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE PHARMACY DISCLAIMS ALL WARRANTIES AND CONDITIONS, EXPRESS OR IMPLIED, IN RESPECT OF THE PRODUCTS AND SERVICES CONTEMPLATED BY THESE TERMS AND CONDITIONS, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. RESPONSIBILITY FOR CLAIMS IN RESPECT OF THE PRODUCTS IS LIMITED TO REPLACEMENT OF THE PRODUCT.</p>
                  <p><strong>Privacy Policy.</strong> You must fully and accurately disclose your personal information and consent to its use by the Pharmacy. By submitting an order to Daye, you have consented to the collection and use of your personal information as described in the Daye Privacy Policy (available online on the Website) and in the Pharmacy Privacy Policy (available online at https://www.thehealthcounter.com/privacy-policy).</p>
                  <p><strong>Return Policy.</strong> The Pharmacy, does not generally accept product returns for any credit unless medications were dispensed in error contrary to the prescription.</p>
                  <p><strong>Severability.</strong> The invalidity and unenforceability of any provisions in these Terms shall not affect the validity or enforceability of any other provisions hereof and any such invalid or unenforceable provision shall be deemed to be severable.</p>
                  <p><strong>Headings.</strong> The section headings used herein are for convenience of reference only and do not form a part of these Terms, and no construction or inference may be derived there from.</p>
                  <p><strong>Force Majeure.</strong> The Pharmacy shall not be liable for any delay or failure in performance caused by circumstances beyond its reasonable control, including, without limitation, delays due to back orders of requested products, mail delays, or lost shipments.
  Governing Law. All agreements reached or contracts formed with the Pharmacy shall be deemed to have been made in the home jurisdiction of the Pharmacy (England and Wales), and the laws of that jurisdiction shall have sole and exclusive jurisdiction over any dispute arising between you and the Pharmacy.
  Contact. You can contact the Pharmacy at customerservice@cedarwoodpharmacy.co.uk or by calling on 0203 940 6770.</p>
                </section>
              </article>
            </Content>
          </div>
        )}
      </PageContainer>
      <Footer />
    </>
  )
}

interface PageContainer {
  loaded: boolean
}

const PageContainer = styled.div`
  transition: opacity 0.5s ease-in, visibility 0.3s ease-in;
  opacity: ${({ loaded }: PageContainer) => (loaded ? 1 : 0)};
  visibility: ${({ loaded }: PageContainer) => (loaded ? "visible" : "hidden")};
`

const Content = styled.div`
  padding-top: 140px;
  margin: 0 auto;
  max-width: 1000px;
  width: 90vw;
  font-family: "BrownStd";

  section {
    padding-bottom: 60px;
  }

  ul {
    margin: 30px 60px;

    li {
      font-size: 16px;
      line-height: 22px;
      color: rgb(0, 58, 27);
    }
  }

  h2 {
    font-size: 4em;
    margin-bottom: 60px;
    font-family: "Domaine";
    color: rgb(0, 58, 27);
  }

  h4 {
    font-size: 18px;
    line-height: 22px;
    font-family: 'BrownStd';
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 20px;
    color: rgb(0, 58, 27);
  }

  p {
    font-size: 16px;
    line-height: 22px;
    color: rgb(0, 58, 27);
    margin: 0 0 20px;
  }

  a {
    text-decoration: underline;

    &:hover {
      font-weight: bold;
    }
  }
`